import {
    TextInput,
} from "react-admin";
import {GenericRelation} from "./GenericRelation";
import {Packs} from "./Packs";

export const Occasions = () => {
    return (
        <GenericRelation source="occasions">
            <TextInput source="name" />
            <Packs />
        </GenericRelation>
    )
}
