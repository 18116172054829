import {GenericRelation} from "./GenericRelation";
import {NumberInput, TextInput} from "react-admin";

export const Packs = () => {
    return (
        <GenericRelation source={"packs"}>
            <TextInput source="name" />
            <TextInput source="description" multiline />
            <NumberInput source="price" />
        </GenericRelation>
    )
}
