import {FC, PropsWithChildren} from "react";
import {ArrayInput, SimpleFormIterator} from "react-admin";
import {Sort} from "./Sort";

type GenericRelationProps = PropsWithChildren<{
    source: string;
}>
export const GenericRelation: FC<GenericRelationProps> = ({children, source}) => {
    return (
        <ArrayInput source={source}>
            <SimpleFormIterator>
                {children}
                <Sort property={source} />
            </SimpleFormIterator>
        </ArrayInput>
    )
}
