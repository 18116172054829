import {HydraAdmin, ResourceGuesser} from "@api-platform/admin";
import {ActivityCreate, ActivityEdit} from "./components/ActivityForm";
import {ActivityList} from "./components/ActivityList";

// @ts-ignore
const apiUrl: string = process.env.REACT_APP_API_URI;

const App = () =>
  <HydraAdmin entrypoint={apiUrl}>
      <ResourceGuesser name={"activities"} list={ActivityList} edit={ActivityEdit} create={ActivityCreate} options={{label: "Aktivitäten"}} />
      <ResourceGuesser name={"occasions"} options={{label: "Anlässe"}} />
  </HydraAdmin>

export {
  App,
}
