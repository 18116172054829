import {List, Datagrid, TextField, EditButton, DeleteButton} from "react-admin"

const ActivityList = () =>
    <List>
        <Datagrid>
            <TextField source={"name"}/>
            <TextField source={"description"}/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>

export {
    ActivityList,
}
