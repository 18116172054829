import {FormDataConsumer} from "react-admin";

type SortProps = {
    property: string;
}
export const Sort = ({property}: SortProps) => {
    return (
        <FormDataConsumer>
            {({formData}) => {
                if (formData.hasOwnProperty(property)) {
                    formData[property].forEach((item: any, index: number) => {
                        if (item.hasOwnProperty('sort') && item.sort !== index) {
                            item.sort = index
                        }
                    })
                }

                return null
            }}
        </FormDataConsumer>
    )
}
