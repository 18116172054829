import {TextInput} from "react-admin";
import {GenericRelation} from "./GenericRelation";

export const Subactivities = () => {
    return (
        <GenericRelation source={"subactivities"}>
            <TextInput source="name" helperText={false}/>
            <TextInput source="description" helperText={false} multiline/>
        </GenericRelation>
    )
}
