import {TextInput} from "react-admin"
import {CreateGuesser, EditGuesser} from "@api-platform/admin";
import {Subactivities} from "./Relations/Subactivities";
import {Occasions} from "./Relations/Occasions";
import {OpeningHours} from "./Relations/OpeningHours";

const ActivityForm = () => {
    return (
        <>
            <TextInput source="name"/>
            <TextInput source="description" multiline/>
            <TextInput source="path"/>
            <Subactivities />
            <Occasions />
            <OpeningHours />
        </>
    )
}

export const ActivityCreate = () => {
    return (
        <CreateGuesser>
            <ActivityForm/>
        </CreateGuesser>
    )
}

export const ActivityEdit = () => {
    return (
        <EditGuesser>
            <ActivityForm/>
        </EditGuesser>
    )
}
