import {TextInput} from "react-admin";
import {GenericRelation} from "./GenericRelation";

export const OpeningHours = () => {
    return (
        <GenericRelation source="openingHours">
            <TextInput source="day" helperText={false}/>
        </GenericRelation>
    )
}
